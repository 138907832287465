import type { AccountProject } from "../lib/Interfaces";
import { ssAuth, getStatusActions, /*getAllStatusEvents,*/ getCurrentStatus } from "./TcApiSS";
import { StatusEvent } from "../lib/Interfaces";

export async function getStatusInfo(
  projects: AccountProject[],
  token: string
): Promise<void> {
  console.log("Start getting all project's status info.");

  const ss_token = await ssAuth(token);

  const promises = projects.map((p) => getProjectStatus(p, ss_token));

  // const results =
  await Promise.allSettled(promises);

  console.log("Got all status info!");
}

async function getProjectStatus(
  project: AccountProject,
  ss_token: string
): Promise<void> {
  console.log("Get status actions here");

  const statusActions = await getStatusActions(project.id, ss_token);
  project.statusActions = statusActions;

  project.statusActions.forEach((p) => (p.projectId = project.id));

  console.log("Got status actions, now get status events");

  // QC status actions only.
  const qcStatusActions = statusActions.filter((a) => a.name.toLowerCase().endsWith("_qc"));

  // Get all status events for only QC status actions.
  const promises = qcStatusActions.map((a) => getCurrentStatus(project.id, ss_token, a.id))

  const results = await Promise.allSettled(promises);

  // Get only those that haven't errored.
  const nonErrors = results.filter((r) => r.status === "fulfilled") as PromiseFulfilledResult<StatusEvent[]>[];

  // Get the value, and convert into a single flat list.
  const events = nonErrors.map((e) => e.value).flat()

  project.statusEvents = events
}
