import React, { useState, useEffect, useCallback } from "react";
import { Spin, Typography } from 'antd';

import type { AccountProject } from "../lib/Interfaces";
import { getAllProjectsData } from "../lib/TcScript";
import GetData from "./GetData"

const { Title, Text } = Typography;

interface Props {
    token: string;
}

// Declares a React functional component, with props passed in.
const UsageData: React.FC<Props> = ({ token }) => {
    // Declare state.
    const [projects, setProjects] = useState<AccountProject[]>([]);
    // const [actionsLoaded, setActionsLoaded] = useState(true);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    // Load the projects actions.
    const loadProjects = useCallback(async () => {
        console.log("Loading projects actions...");

        // Reset the loading.
        setIsLoading(true);
        setError(null);

        try {
            // Load all the projects.
            const allProjects = await getAllProjectsData(token);
            setProjects(allProjects);
        } catch (error) {
            // Set an error message if it fails to load.
            console.error(error);
            setError("Failed to load projects. Please try again.");
        } finally {
            // Stop the loading flag.
            setIsLoading(false);
        }
    }, [token]);

    // Only runs if isLoading or loadStatusActions changes.
    useEffect(() => {
        if (isLoading) {
            loadProjects();
        }
    }, [isLoading, loadProjects]);


    return (
        <div>
            <Title level={3}>Projects</Title>
            {!isLoading && (projects.length === 0) && <Text type="warning">No projects found.</Text>}

            {error && <Text type="warning">{error}</Text>}

            {isLoading && (<Spin />)}

            {!isLoading && (projects.length > 0) && (
                <GetData
                    token={token}
                    projects={projects}
                />
            )}
        </div>
    );
};

export default UsageData;
