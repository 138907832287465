import React, { useState, useCallback } from "react";
import { Button, Spin, Space, Typography } from 'antd';

import type { AccountProject } from "../lib/Interfaces";
import { exportToCsv } from "../lib/TcScript";
import { getFiles } from "../lib/FileInfo";
import { getStatusInfo } from "../lib/StatusSharing";

const { Title, Text } = Typography;

interface Props {
    token: string;
    projects: AccountProject[]
}

// Declares a React functional component, with props passed in.
const GetData: React.FC<Props> = ({ token, projects }) => {
    // Declare state.
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // Load the projects actions.
    const getAllFiles = useCallback(async () => {
        console.log("Loading files actions...");

        // Reset the loading.
        setIsLoading(true);
        setError(null);

        try {
            // Load all the projects.
            await getFiles(projects, token);
        } catch (error) {
            // Set an error message if it fails to load.
            console.error(error);
            setError("Failed to load file info. Please try again.");
        } finally {
            // Stop the loading flag.
            setIsLoading(false);
        }
    }, [token, projects]);

    // Load the projects actions.
    const getAllStatus = useCallback(async () => {
        console.log("Loading files actions...");

        // Reset the loading.
        setIsLoading(true);
        setError(null);

        try {
            // Load all the projects.
            await getStatusInfo(projects, token);
        } catch (error) {
            // Set an error message if it fails to load.
            console.error(error);
            setError("Failed to load statuses. Please try again.");
        } finally {
            // Stop the loading flag.
            setIsLoading(false);
        }
    }, [token, projects]);

    // Load the projects actions.
    const exportCsv = useCallback(() => {
        console.log("Exporting to CSV...");

        // Reset the loading.
        setIsLoading(true);
        setError(null);

        try {
            // Load all the projects.
            exportToCsv(projects);
        } catch (error) {
            // Set an error message if it fails to load.
            console.error(error);
            setError("Failed to export to CSV. Please try again.");
        } finally {
            // Stop the loading flag.
            setIsLoading(false);
        }
    }, [projects]);

    return (
        <div>
            <Title level={4}>Download data</Title>
            {error && <Text type="warning">{error}</Text>}

            {isLoading && (<Spin />)}

            <Space>
                {!isLoading && <Button type="primary" onClick={() => getAllFiles()}>Load files</Button>}

                {!isLoading && <Button type="primary" onClick={() => getAllStatus()}>Load status</Button>}

                {!isLoading && <Button type="primary" onClick={() => exportCsv()}>Export CSV</Button>}
            </Space>
        </div>
    );
};

export default GetData;
