import type { AccountProject, FolderItem } from "../lib/Interfaces";
//import type { TCExtensionAPI } from "trimble-connect-project-workspace-api";
import { getFolderItems } from "./TcApi";

export async function getFiles(
    projects: AccountProject[],
    token: string
): Promise<void> {
    console.log("Start getting all project's file info");

    projects.forEach((p) => {
        p.items = [];
    });

    const promises = projects.map((p) =>
        getAllFileInfo(p, p.details.rootId, token)
    );

    // const results =
    await Promise.allSettled(promises);
}

async function getAllFileInfo(
    project: AccountProject,
    folderId: string,
    token: string
): Promise<void> {
    console.log("Get file info here");
    // The root
    const items = await getFolderItems(project.url, folderId, token);

    console.log("Got file info");

    // Add all the items to the item list.
    items.forEach((i) => project.items.push(i));

    // Get just the files and attempt to get the file type.
    const files = items.filter((i) => i.type === "FILE");
    files.forEach((f) => (f.fileType = getExtension(f.name)));

    // Get the full path of the file / folder.
    items.forEach((i) => buildPath(i));

    // Get just the folders, and recursivelly go through them.
    const folders = items.filter((i) => i.type === "FOLDER");
    console.log(`Found ${folders.length} no searching recursively`);

    const promises = folders.map((f) => getAllFileInfo(project, f.id, token));

    await Promise.allSettled(promises);
}

function getExtension(fileName: string): string {
    const extension = fileName.split(".").pop();

    // If no extension found, return empty.
    if (extension === fileName) return "";

    if (extension === undefined) return "";

    return extension.toLowerCase();
}

function buildPath(item: FolderItem) {
    const pathNames = item.path.map((p) => p.name.replaceAll(",", ""));

    // Remove the first one, because that's always the project name.
    pathNames.shift();
    pathNames.unshift("root");

    // Add Root at the beginning.
    item.pathString = pathNames.join("/");
}
