//import { WorkspaceAPI } from 'workspace-api';
import type { WorkspaceAPI } from "trimble-connect-workspace-api";
import {
  filesToCsv,
  projectsToCsv,
  statusActionsToCsv,
  statusEventsToCsv,
  usersProjectsMapCsv,
  usersToCsv,
} from "./CsvExporter";

import type {
  AccountProject,
  Region,
  License,
  User,
  Account,
  ProjectDetails,
} from "./Interfaces";
import {
  getRegions,
  getLicenses,
  getAccountProjects,
  getProjectDetails,
  getProjectTodos,
  getProjectUsers,
  getProjectViews,
  getProjectFileActivity,
} from "./TcApi";

export async function addMenu(api: WorkspaceAPI) {
  // Determine if in dev or prod.
  const currentUrl = window.location.href;
  console.log(`Current URL: ${currentUrl}`);

  const mainMenuObject = {
    title: "Extract data (V2)",
    icon: "https://tc-get-data.pages.dev/prod.png",
    command: "main_nav_menu_cliked",
  };

  // Replace menu values if it is hosted locally.
  if (currentUrl.includes("127.0.0.1") || currentUrl.includes("localhost")) {
    mainMenuObject.title = "Extract data (Dev)";
    mainMenuObject.icon = "http://localhost:3000/dev.png";
  }

  await api.ui.setMenu(mainMenuObject);
}

export async function getAllProjectsData(
  token: string
): Promise<AccountProject[]> {
  // Get the master region
  const regions = await getRegions(token);
  const masterUrl = regions.find((r) => r.isMaster === true)?.["tc-api"];
  if (!masterUrl) return [];

  // Get licenses, accounts, and projects
  const licenses = await getLicenses(masterUrl, token);
  const accounts = getAccountsFromLicense(licenses);
  const allProjects = await getAllProjectsInAllAccounts(
    accounts,
    regions,
    token
  );

  return allProjects;
}

export async function getProjectsData(api: WorkspaceAPI) {
  console.log("STARTING MAIN FUNCTION!");

  const token = await api.extension.getPermission("accesstoken");

  const regions = await getRegions(token);
  const masterUrl = regions.find((r) => r.isMaster === true)?.["tc-api"];
  if (!masterUrl) return;

  const licenses = await getLicenses(masterUrl, token);
  console.log(licenses);

  // Get the unique accounts IDs
  const accounts = getAccountsFromLicense(licenses);

  const allProjects = await getAllProjectsInAllAccounts(
    accounts,
    regions,
    token
  );

  // Add user details.
  console.log(allProjects);

  const allUsers: User[] = [];
  allProjects.forEach((p) => p.users.forEach((u) => allUsers.push(u)));
  // const userIds = allUsers.map((u) => u.id);
  // const uniqueUserIds = Array.from(new Set(userIds));
  // const uniqueUsers = uniqueUserIds.map((i) =>
  //   allUsers.find((a) => a.id === i)
  // );

  // const allViews = allProjects.map((p) => p.views).flat();
  // const allTodos = allProjects.map((p) => p.todos).flat();

  // Export to csv files.
  //projectsToCsv(allProjects.map(p => p.details))
  //usersToCsv(uniqueUsers)
  //usersProjectsMapCsv(allProjects)
  //viewsToCsv(allViews)
  //todosToCsv(allTodos)
}

export function exportToCsv(allProjects: AccountProject[]) {
  // Get all the user data
  const allUsers: User[] = [];
  allProjects.forEach((p) => p.users.forEach((u) => allUsers.push(u)));
  const userIds = allUsers.map((u) => u.id);
  const uniqueUserIds = Array.from(new Set(userIds));
  const uniqueUsers = uniqueUserIds.map(
    (i) => {
      const user = allUsers.find((a) => a.id === i);
      if (!user) {
        throw new Error(`User with id ${i} not found`);
      }
      return user;
    }
  );

  // Export to CSV.

  // Add the last activity to the details.
  for (const p of allProjects) p.details.lastFileActivity = p.lastFileActivity;

  const toExport = allProjects.map((p) => p.details);
  projectsToCsv(toExport);
  usersToCsv(uniqueUsers);
  usersProjectsMapCsv(allProjects);
  filesToCsv(allProjects);
  statusActionsToCsv(allProjects);
  statusEventsToCsv(allProjects);
}

function getAccountsFromLicense(licenses: License[]): Account[] {
  const accounts = licenses.map((l) => l.accounts[0]);
  const accountIds = accounts.map((a) => a.accountId);
  const uniqueAccountIds = Array.from(new Set(accountIds));
  const uniqueAccounts = uniqueAccountIds.map(
    (i) => {
      const account = accounts.find((a) => a.accountId === i);
      if (!account) {
        throw new Error(`User with id ${i} not found`);
      }
      return account;
    }
  );

  return uniqueAccounts;
}

export async function getAllProjectsInAllAccounts(
  accounts: Account[],
  regions: Region[],
  token: string
): Promise<AccountProject[]> {
  // Get all projects in all accounts
  const allProjects: AccountProject[] = [];
  for (const account of accounts) {
    for (const region of regions) {
      try {
        console.log(
          `Getting projects in account: ${account.source}\tRegion: ${region.location}`
        );
        const projects = await getAccountProjects(
          region,
          account.accountId,
          token
        );

        // Skip to next one if no projects found.
        if (projects.length === 0) continue;

        projects.forEach((p) => allProjects.push(p));

        projects.forEach((p) => (p.url = region["tc-api"]));

        // Add details to each project in this region.
        await addDetailsToProjects(region, projects, token);

        // Add the projects
        await addUsersToProjects(region, projects, token);

        // Add file activity to projects
        //await addFileActivityToProjects(region, projects, token);

        // Add the views
        //await addViewsToProjects(region, projects, token)

        // Add the views
        //await addTodosToProjects(region, projects, token)
      } catch (error) {
        console.error(error);
      }
    }
  }

  return allProjects;
}

async function addDetailsToProjects(
  region: Region,
  projects: AccountProject[],
  token: string
) {
  // Get the detail of each projcet.
  const projectDetailPromises = projects.map((p) =>
    getProjectDetails(region["tc-api"], p.id, token)
  );
  const projectDetailResults = await Promise.all(projectDetailPromises);

  for (let i = 0; i < projectDetailResults.length; i++) {
    const details = projectDetailResults[i];
    const project = projects[i];

    readNameData(details);

    project.details = details;
  }
}

function readNameData(details: ProjectDetails) {
  const name = details.name;

  const sections = name.split("_");

  // Exit if it's not been split
  if (sections.length === 1) {
    details.nameDescription = name;
    return;
  }

  // Split code into constituent parts.
  const fields = sections[0].split("-");

  details.scope = fields[0];

  if (fields.length > 1) details.unit = fields[1];
  if (fields.length > 2) details.building = fields[2];
  if (fields.length > 3) details.level = fields[3];
}

async function addUsersToProjects(
  region: Region,
  projects: AccountProject[],
  token: string
) {
  // Get the basic data of users in a project.
  const usersInProjects = projects.map((p) =>
    getProjectUsers(region["tc-api"], p.id, token)
  );
  const result = await Promise.all(usersInProjects);

  for (let i = 0; i < result.length; i++) {
    const users = result[i];
    const project = projects[i];

    project.users = users;
  }
}

export async function addFileActivityToProjects(
  region: Region,
  projects: AccountProject[],
  token: string
) {
  // Get the basic data of users in a project.
  const fileActivityInProject = projects.map((p) =>
    getProjectFileActivity(region["tc-api"], p.id, token)
  );
  const result = await Promise.all(fileActivityInProject);

  for (let i = 0; i < result.length; i++) {
    const lastFileActivity = result[i];
    const project = projects[i];

    project.lastFileActivity = lastFileActivity;
  }
}

export async function addViewsToProjects(
  region: Region,
  projects: AccountProject[],
  token: string
) {
  // Get the basic data of users in a project.
  const viewsInProjects = projects.map((p) =>
    getProjectViews(region["tc-api"], p.id, token)
  );
  const result = await Promise.all(viewsInProjects);

  for (let i = 0; i < result.length; i++) {
    const views = result[i];
    const project = projects[i];

    project.views = views;
  }
}

export async function addTodosToProjects(
  region: Region,
  projects: AccountProject[],
  token: string
) {
  // Get the basic data of users in a project.
  const todosInProjects = projects.map((p) =>
    getProjectTodos(region["tc-api"], p.id, token)
  );
  const result = await Promise.all(todosInProjects);

  for (let i = 0; i < result.length; i++) {
    const todos = result[i];
    const project = projects[i];

    project.todos = todos;
  }
}

export interface ProjectUserPair {
  project: AccountProject;
  user: User;
}

export function projectIncludesEmailOffline(
  project: AccountProject,
  emails: string[]
): ProjectUserPair[] {
  const activeUsers = project.users.filter(
    (u) =>
      u.status.toLowerCase() === "active" ||
      u.status.toLowerCase() === "pending"
  );
  const activeUserEmails = activeUsers.map((u) => u.email);

  const removePairs: ProjectUserPair[] = [];

  for (const email of emails) {
    const index = activeUserEmails.indexOf(email);
    if (index !== -1) {
      const removePair = {
        project: project,
        user: activeUsers[index],
      };
      removePairs.push(removePair);
    }
  }

  return removePairs;
}

export function isProtectedProject(project: AccountProject): boolean {
  const protectedProjectIds = ["NfaKHYEtQ6I"];

  if (protectedProjectIds.includes(project.id)) return true;

  return false;
}

export interface ProjectUserRole {
  project: AccountProject;
  user: string;
  role: string;
}

export function projectsNotIn(
  emails: string[],
  projects: AccountProject[],
  role: string
): ProjectUserRole[] {
  const toAddData: Array<ProjectUserRole> = [];

  // Go through each project.
  for (const project of projects) {
    const projectUserEmails = project.users
      .filter((u) => u.status === "ACTIVE")
      .map((u) => u.email);

    // Go through each emali.
    for (const email of emails) {
      // If the email isn't active in the project.
      if (projectUserEmails.includes(email) === false) {
        // Add to the list of add data.
        const add: ProjectUserRole = {
          project: project,
          user: email,
          role: role,
        };
        toAddData.push(add);
      }
    }
  }

  return toAddData;
}
