import React, { useState, useEffect } from "react";
import { Button, Typography } from 'antd';

import { WorkspaceAPI } from "trimble-connect-workspace-api";
import { connect } from "trimble-connect-workspace-api";
import UsageData from "./UsageData";
import { addMenu } from "../lib/TcScript";

import { ssAuth } from "../lib/TcApiSS";

const { Title, Text } = Typography;


// Declares a React functional component.
const App: React.FC = () => {
    // Declare state.
    const [api, setApi] = useState<WorkspaceAPI>({} as WorkspaceAPI);
    const [token, setToken] = useState<string>("");
    const [ssToken, setSsToken] = useState<string>("");

    // A derived state variable.
    const init = ssToken && api;

    const initApi = async () => {
        try {
            // Connect to the Trimble Connect Workspace API.
            const api = await connect(window.parent);
            setApi(api);

            // Add the menu.
            await addMenu(api)

            // Get an authentication token.
            const token = await api.extension.requestPermission("accesstoken");
            setToken(token);

            // Get a status sharing token.
            const ssToken = await ssAuth(token);
            setSsToken(ssToken);
        } catch (error) {
            console.error("Failed to initialize API:", error);
        }
    };

    // Runs once when the component is loaded. The empty array ensures it only runs once.
    useEffect(() => {
        initApi();
    }, []);

    // Rendering.
    return (
        <div>
            <main>
                <img src="../bylor_logo.png" style={{ width: "100px", height: "auto" }} alt="Bylor Logo" />
                <Title level={2}>Bylor TC Tools (Beta)</Title>

                {Object.keys(api).length === 0 && <Text type="warning">Not connected to API.</Text>}

                {token === "" && (
                    <div>
                        <Text type="warning">No token, extension not initialised.</Text>
                        <div>
                            <Button type="primary" onClick={() => initApi()}>Initialise API</Button>
                        </div>
                    </div>
                )}

                {init && <UsageData token={token} />}
            </main>
        </div>
    );
};

export default App;
