import type {
    User,
    AccountProject,
    ProjectDetails,
    View,
    Todo,
} from "./Interfaces";

export function projectsToCsv(projects: ProjectDetails[]) {
    const values = projects.map((p) => [
        p.id,
        p.name?.replaceAll(",", " "),
        p.rootId,
        p.location,
        p.lastVisitedOn,
        p.modifiedOn,
        p.createdOn,
        p.createdBy.email ?? "",
        p.modifiedBy.email ?? "",
        p.updatedOn,
        p.updatedBy.email ?? "",
        p.size.toString(),
        p.filesCount.toString(),
        p.foldersCount.toString(),
        p.versionsCount.toString(),
        p.usersCount.toString(),
        p.description?.replaceAll(",", " "),
        p.license.subscriptionId,
        p.access,
        p.lastFileActivity,
        p.scope,
        p.unit,
        p.building,
        p.level,
    ]);

    const header = [
        "projectId",
        "projectName",
        "rootId",
        "location",
        "lastVisitedOn",
        "projectModifiedOn",
        "projectCreatedOn",
        "createdByUserEmail",
        "modifiedByUserEmail",
        "updatedOn",
        "updatedByUserEmail",
        "size",
        "filesCount",
        "foldersCount",
        "versionsCount",
        "usersCount",
        "description",
        "subscriptionId",
        "access",
        "lastFileActivity",
        "scope",
        "unit",
        "building",
        "level",
    ].join(",");

    exportCSVFile(values, "Projects.csv", header);
}

export function usersToCsv(users: User[]) {
    const values = users.map((u) => [
        u.status,
        u.id,
        u.tiduuid,
        u.email,
        u.email?.split("@")[1],
        u.firstName,
        u.lastName,
        u.phone,
        u.language,
        u.podLocation,
        u.createdOn,
        u.modifiedOn,
        u.companyAdmin?.toString(),
    ]);

    const header = [
        "status",
        "userId",
        "tiduuid",
        "email",
        "emailDomain",
        "firstName",
        "lastName",
        "phone",
        "language",
        "podLocation",
        "createdOn",
        "modifiedOn",
        "companyAdmin",
    ].join(",");

    exportCSVFile(values, "Users.csv", header);
}

export function filesToCsv(projects: AccountProject[]) {
    // const values =

    const allItems = projects.map((p) => p.items).flat().filter((i) => i !== undefined);

    const values = allItems.map((i) => [
        i.id,
        i.createdBy.email,
        i.createdOn,
        i.hash,
        i.modifiedBy.email,
        i.modifiedOn,
        i.name?.replaceAll(",", " "),
        i.revision?.toString(),
        i.size?.toString(),
        i.type,
        i.projectId,
        i.fileType,
        i.pathString,
    ]);

    const header = [
        "itemId",
        "createdBy",
        "createdOn",
        "hash",
        "modifiedBy",
        "modifiedOn",
        "name",
        "revision",
        "itemSize",
        "type",
        "projectId",
        "fileType",
        "path",
    ].join(",");

    exportCSVFile(values, "Files.csv", header);
}

export function statusActionsToCsv(projects: AccountProject[]) {
    // const values =

    const allItems = projects
        .map((p) => p.statusActions)
        .flat()
        .filter((i) => i !== undefined);

    const values = allItems.map((i) => [
        i.id,
        i.organizationId,
        i.isPublic.toString(),
        i.isPersonal.toString(),
        i.allowedValues.replaceAll(",", "|"),
        i.isWritable.toString(),
        i.changeDate,
        i.changeUserId,
        i.name?.replaceAll(",", " "),
        i.projectId,
    ]);

    const header = [
        "statusActionId",
        "organizationId",
        "isPublic",
        "isPersonal",
        "allowedValues",
        "isWritable",
        "changeDate",
        "changeUserId",
        "statusActionName",
        "projectId",
    ].join(",");

    exportCSVFile(values, "StatusActions.csv", header);
}

export function statusEventsToCsv(projects: AccountProject[]) {
    const allItems = projects
        .map((p) => p.statusEvents)
        .flat()
        .filter((i) => i !== undefined);

    const values = allItems.map((i) => [
        i.id,
        i.objectId,
        i.statusActionId,
        i.value,
        i.valueDate,
        i.changeDate,
        i.changeUserId,
    ]);

    const header = [
        "statusEventId",
        "objectId",
        "statusActionId",
        "value",
        "valueDate",
        "changeDate",
        "changeUserId",
    ].join(",");

    exportCSVFile(values, "StatusEvents.csv", header);
}

export function viewsToCsv(views: View[]) {
    const values = views.map((v) => [
        v.id,
        v.name?.replaceAll(",", " "),
        v.projectId,
        v.createdOn,
        v.modifiedOn,
        v.createdBy.email,
    ]);

    const header = [
        "viewId",
        "viewName",
        "projectId",
        "viewCreatedOn",
        "viewModifiedOn",
        "viewCreatedBy",
    ].join(",");

    exportCSVFile(values, "Views.csv", header);
}

export function todosToCsv(views: Todo[]) {
    const values = views.map((v) => [
        v.id,
        v.title?.replaceAll(",", " "),
        v.projectId,
        v.createdOn,
        v.modifiedOn,
        v.createdBy.email,
    ]);

    const header = [
        "todoId",
        "todoName",
        "projectId",
        "todoCreatedOn",
        "todoModifiedOn",
        "todoCreatedBy",
    ].join(",");

    exportCSVFile(values, "Todos.csv", header);
}

export function usersProjectsMapCsv(projects: AccountProject[]) {
    const values: string[][] = [];

    projects.forEach((p) =>
        p.users.forEach((u) => values.push([p.id, u.id, u.role, u.lastAccessed]))
    );

    const header = [
        "projectId",
        "userId",
        "projectUserRole",
        "lastAccessed",
    ].join(",");

    exportCSVFile(values, "UserProjectMap.csv", header);
}

function exportCSVFile(values: string[][], fileTitle: string, headers: string) {
    const lines = values.map((v) => v.join(","));
    lines.unshift(headers);
    const text = lines.join("\r\n");

    console.log("Start download.");

    const blob = new Blob([text], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileTitle);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
