import type { StatusAction, StatusEvent } from "./Interfaces";

export async function ssAuth(
  //baseUrl: string,
  token: string
): Promise<string> {
  const baseUrl = "https://europe.tcstatus.tekla.com/statusapi/1.0";

  const url = `${baseUrl}/auth/token`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  if (response.ok === false) {
    throw new Error("Failed to get Status Sharing authorization.");
  }

  const data = await response.text();

  const raw_data = data.replaceAll('"', "");

  return raw_data;
}

export async function getStatusActions(
  projectId: string,
  ss_token: string
): Promise<StatusAction[]> {
  const baseUrl = "https://europe.tcstatus.tekla.com/statusapi/1.0";

  const url = `${baseUrl}/projects/${projectId}/statusactions`;

  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${ss_token}` },
  });

  if (response.ok === false) {
    //throw new Error("Failed to retrieve project todos");
  }
  const data = await response.json();

  return data as StatusAction[];
}

export async function getStatusEvents(
  projectId: string,
  ss_token: string
): Promise<StatusEvent[]> {
  const baseUrl = "https://europe.tcstatus.tekla.com/statusapi/1.0";

  const url = `${baseUrl}/projects/${projectId}/statusevents`;

  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${ss_token}` },
  });

  if (response.ok === false) {
    //throw new Error("Failed to retrieve project todos");
  }
  const data = await response.json();

  return data as StatusEvent[];
}

export async function getCurrentStatus(
  projectId: string,
  ss_token: string,
  statusActionId: string,
  objectId?: string,
): Promise<StatusEvent[]> {
  const baseUrl = "https://europe.tcstatus.tekla.com";
  const url = new URL(`/statusapi/1.0/projects/${projectId}/status`, baseUrl);
  url.searchParams.append('statusActionId', statusActionId);

  if (objectId !== undefined)
    url.searchParams.append("objectId", objectId)

  const response = await fetch(url.toString(), {
    headers: { Authorization: `Bearer ${ss_token}` },
  });

  if (response.ok === false)
    throw new Error("Failed to retrieve project statuses");

  const data = await response.json();

  return data as StatusEvent[];
}

export async function getAllStatusEvents(
  projectId: string,
  ss_token: string,
  statusActionId: string,
  objectId?: string,
): Promise<StatusEvent[]> {
  const baseUrl = "https://europe.tcstatus.tekla.com";
  const url = new URL(`/statusapi/1.0/projects/${projectId}/statusevents`, baseUrl);
  url.searchParams.append('statusActionId', statusActionId);

  if (objectId !== undefined)
    url.searchParams.append("objectId", objectId)

  const response = await fetch(url.toString(), {
    headers: { Authorization: `Bearer ${ss_token}` },
  });

  if (response.ok === false)
    throw new Error("Failed to retrieve project statuses");

  const data = await response.json();

  return data as StatusEvent[];
}

export async function createEvent(
  projectId: string,
  objectIds: Array<string>,
  statusActionId: string,
  value: number,
  changeUserId: string,
  ss_token: string
): Promise<void> {
  // Values: 0: None, 1: Enable, 2: Commit, 3: Started, 4: Paused, 5: Completed
  const baseUrl = "https://europe.tcstatus.tekla.com/statusapi/1.0";

  const url = `${baseUrl}/projects/${projectId}/statusevents`;

  const valueDate = new Date().toISOString();
  const changeDate = valueDate;

  const eventsData = [];

  for (const objectId of objectIds) {
    const eventData = {
      objectId,
      statusActionId,
      value,
      valueDate,
      changeDate,
    };
    eventsData.push(eventData);
  }

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${ss_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(eventsData),
  });
  if (response.ok === false) {
    throw new Error("Failed to get Status Sharing authorization.");
  }

  //return raw_data;
}

export function valueToId(value: string): number {
  const valueToIdMap: {
    [key: string]: number;
  } = {};

  valueToIdMap["None"] = 0;
  valueToIdMap["Enable"] = 1;
  valueToIdMap["Commit"] = 2;
  valueToIdMap["Started"] = 3;
  valueToIdMap["Paused"] = 4;
  valueToIdMap["Completed"] = 5;

  return valueToIdMap[value];
}