import React from 'react';
import './App.css';
import TcApp from "./components/TcApp";
import { ConfigProvider } from 'antd';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ConfigProvider theme={{ token: { colorPrimary: '#DF4C00' } }}>
          <TcApp />
        </ConfigProvider>
      </header>
    </div>
  );
}

export default App;
