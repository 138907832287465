import type {
  AccountProject,
  View,
  UserByAccount,
  UsersByAccount,
  Todo,
  Activity,
  Region,
  ProjectDetails,
  License,
  AccountProjectUser,
  AccountProjects,
  AccountUsers,
  User,
  FolderItem,
} from "./Interfaces";

export async function getRegions(token: string): Promise<Region[]> {
  const masterUrl = "https://app.connect.trimble.com/tc/api/2.0/";
  const url = `${masterUrl}regions`;
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.ok === false) {
    throw new Error("Failed to retrieve list of regions");
  }
  const data = await response.json();

  return data as Region[];
}

export async function getLicenses(
  baseUrl: string,
  token: string
): Promise<License[]> {
  const url = `${baseUrl}users/licenses`;
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.ok === false) {
    throw new Error("Failed to retrieve list of licenses");
  }
  const data = await response.json();

  return data as License[];
}

export async function getAccountProjects(
  region: Region,
  accountId: string,
  token: string
): Promise<AccountProject[]> {
  let pageNumber = 0;
  let hasNextPage = true;
  const projects: AccountProject[] = [];

  while (hasNextPage) {
    const url = `${region["projects-api"]}accounts/${accountId}/projects?page=${pageNumber}`;

    const response = await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    // If there's no projects, return an empty list.
    if (response.status === 204 || response.ok === false) {
      console.info("No projects in account");
      return [];
    }

    const data = await response.json();
    const accountProjects = data as AccountProjects;

    accountProjects.content.forEach((p) => projects.push(p));

    pageNumber++;
    hasNextPage = accountProjects.hasNextPage;
  }

  return projects;
}

export async function getAccountProjectUsers(
  baseUrl: string,
  projectId: string,
  token: string
): Promise<AccountProjectUser[]> {
  //accounts/{accountId}/projects?page={number}
  let pageNumber = 0;
  let hasNextPage = true;
  const users: AccountProjectUser[] = [];

  while (hasNextPage) {
    const url = `${baseUrl}projects/${projectId}/users?page=${pageNumber}`;

    const response = await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.ok === false) {
      throw new Error("Failed to retrieve list of Users in project.");
    }
    const data = await response.json();
    const accountUsers = data as AccountUsers;
    accountUsers.content.forEach((u) => users.push(u));

    pageNumber++;
    hasNextPage = accountUsers.hasNextPage;
  }

  return users;
}

export async function getProjectUsers(
  baseUrl: string,
  projectId: string,
  token: string
): Promise<User[]> {
  const url = `${baseUrl}projects/${projectId}/users`;
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.ok === false) {
    throw new Error("Failed to retrieve project users");
  }
  const data = await response.json();

  return data as User[];
}

export async function getProjectFileActivity(
  baseUrl: string,
  projectId: string,
  token: string
): Promise<string> {
  const url = `${baseUrl}activities?projectId=${projectId}&filters=FILE`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      range: "items=0-0",
    },
  });
  if (response.ok === false) {
    return "";
    //throw new Error("Failed to retrieve project activity");
  }
  const data: Array<Activity> = await response.json();

  if (data.length === 0) return "";

  const first: Activity = data[0];

  return first.createdOn;
}

export async function getProjectDetails(
  baseUrl: string,
  projectId: string,
  token: string
): Promise<ProjectDetails> {
  const url = `${baseUrl}projects/${projectId}`;
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.ok === false) {
    throw new Error("Failed to retrieve project details");
  }
  const data = await response.json();

  return data as ProjectDetails;
}

export async function getUsersDetails(
  baseUrl: string,
  token: string,
  userIds: string[]
): Promise<User[]> {
  // Split into chunks of 50 users to not overwhelm the browser.
  const chunks: string[][] = [];
  const chunkSize = 10;
  for (let i = 0; i < userIds.length; i += chunkSize) {
    const chunk = userIds.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  const userDetails: User[] = [];

  for (const chunk of chunks) {
    const promises = chunk.map((u) => getUserDetails(baseUrl, u, token));
    const users = await Promise.all(promises);
    users.forEach((u) => userDetails.push(u));
  }

  return userDetails;
}

export async function getUserDetails(
  baseUrl: string,
  userId: string,
  token: string
): Promise<User> {
  const url = `${baseUrl}users/${userId}`;
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.ok === false) {
    throw new Error("Failed to retrieve user details");
  }
  const data = await response.json();

  return data as User;
}

export async function getAccountUsers(
  accountId: string,
  token: string
): Promise<UserByAccount[]> {
  //accounts/{accountId}/projects?page={number}
  const baseUrl = "https://ecom.connect.trimble.com/v1/";
  let pageNumber = 0;
  let hasNextPage = true;
  const users: UserByAccount[] = [];

  while (hasNextPage) {
    const url = `${baseUrl}accounts/${accountId}/users?page=${pageNumber}`;

    const response = await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.ok === false) {
      throw new Error("Failed to retrieve list of Projects");
    }
    const data = await response.json();
    const accountProjects = data as UsersByAccount;

    accountProjects.content.forEach((p) => users.push(p));

    pageNumber++;
    hasNextPage = accountProjects.hasNextPage;
  }

  return users;
}

export async function getProjectViews(
  baseUrl: string,
  projectId: string,
  token: string
): Promise<View[]> {
  const url = `${baseUrl}views?detail=false&projectId=${projectId}`;
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.ok === false) {
    //throw new Error
    console.warn(`Failed to retrieve project views! Project ID: ${projectId}`);
    return [];
  }
  const data = await response.json();

  return data as View[];
}

export async function getProjectTodos(
  baseUrl: string,
  projectId: string,
  token: string
): Promise<Todo[]> {
  const url = `${baseUrl}todos?projectId=${projectId}`;
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.ok === false) {
    //throw new Error("Failed to retrieve project todos");
  }
  const data = await response.json();

  return data as Todo[];
}

export async function removeUserFromProject(
  baseUrl: string,
  projectId: string,
  userId: string,
  token: string
): Promise<undefined> {
  const url = `${baseUrl}projects/${projectId}/users/${userId}`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.ok === false) {
    throw new Error("Failed to remove from project.");
  }
  //const data = await response.json();

  return;
}

export async function addUserToProject(
  baseUrl: string,
  projectId: string,
  email: string,
  role: string,
  token: string
): Promise<undefined> {
  const url = `${baseUrl}projects/${projectId}/users?notify=false`;

  const data = {
    email: email,
    role: role,
    group: "",
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (response.ok === false) {
    throw new Error("Failed to add to project.");
  }

  return;
}

export async function getFolderItems(
  baseUrl: string,
  folderId: string,
  token: string
): Promise<FolderItem[]> {
  const url = `${baseUrl}folders/${folderId}/items`;

  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.ok === false) {
    //throw new Error("Failed to retrieve project todos");
  }
  const data = await response.json();

  return data as FolderItem[];
}
